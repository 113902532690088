import React from 'react';
import Swiper from 'swiper/js/swiper.esm.bundle';
import styled, { createGlobalStyle } from 'styled-components';
import Image from "../../components/image"

const GlobalStyle = createGlobalStyle`
  .swiper-wrapper {
    margin-bottom: 2rem;
  }

  .swiper-pagination {
    font-weight: bold;
  }

  .swiper-button.swiper-button-next,
  .swiper-button.swiper-button-prev {
    color: #333;
  }

  @media only screen and (max-width: 639px)  {
    .swiper-button.swiper-button-next,
    .swiper-button.swiper-button-prev {
      display: none;
    }
  }

`;

const Slide = styled.div`
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // dummy slides data
      slides: (function () {
        var slides = [];
        for (var i = 0; i < 1000; i += 1) {
          slides.push('Lie ' + (i + 1));
        }
        return slides;
      }()),
      // virtual data
      virtualData: {
        slides: [],
      },
    }
  }

  componentDidMount() {
    const self = this;
    const swiper = new Swiper('.swiper-container', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      virtual: {
        slides: self.state.slides,
        renderExternal(data) {
          // assign virtual slides data
          self.setState({
            virtualData: data,
          });
        }
      },
      keyboard: {
        enabled: true,
      },
    });
  }

  render() {
    return (
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {/* It is important to set "left" style prop on every slide */}
          {this.state.virtualData.slides.map((slide, index) => (
            <div className="swiper-slide"
              key={index}
              style={{ left: `${this.state.virtualData.offset}px` }}
            >
              <Slide>
                {slide}
                <Image />
              </Slide>
            </div>
          ))}
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button swiper-button-next"></div>
        <div className="swiper-button swiper-button-prev"></div>
        <GlobalStyle />
      </div>
    );
  }
}