import React from "react"
// import { Link } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Swiper from "../components/Swiper"

const Heading = styled.h1`
  margin-top: 2rem;
`;

const Welcome = styled.p`
  margin-bottom: 4rem;
`;

const IndexPage = () => (
  <Layout>
    <Helmet>
      <link rel="stylesheet" href="https://unpkg.com/swiper/css/swiper.min.css" />
    </Helmet>
    <SEO title="Home" />
    <Heading>Hi people</Heading>
    <Welcome>Welcome to T-shirt of Lies</Welcome>
    <Swiper />
  </Layout>
)

export default IndexPage
